/* eslint-disable */
import React, { Component, Suspense } from 'react'
import { useEffect, useState } from 'react';
import { useAuth } from "react-oidc-context";
import { useIdleTimer } from 'react-idle-timer'
import APIEndpoints from 'src/components/api/APIEndpoints.js'
import Authorization from 'src/components/authorization/Authorization.js'
import { AppLoading } from './components/index.js'
import { init as initApm } from '@elastic/apm-rum'
import './scss/style.scss'
import {
  CButton,
  CModal,
  CModalHeader,
  CModalTitle,
  CModalFooter,
  CModalBody,
} from '@coreui/react'


const apm = initApm({
      serviceName: 'chb-schmidt',
      serverUrl: 'https://7445684f709c4d1f81b9d874f608cc34.apm.westeurope.azure.elastic-cloud.com:443',
      serviceVersion: '',
      environment: process.env.NODE_ENV
})

// Containers
const LoginPage = React.lazy(() => import('./pages/login/login'))


const App = (props) => {
    const auth = useAuth();
    const [state, setState] = useState('Active')
    const [open, setOpen] = useState(false)

    const onIdle = () => {
      auth.removeUser();
      return <LoginPage error="Sie wurden aufgrund von Inaktivität ausgeloggt. Loggen Sie sich erneut ein, um die Recherche fortzusetzen."/>;
    }

    const onActive = () => {
      setState('Active')
      setOpen(false)
    }

    const onPrompt = () => {
      setState('Prompted')
      setOpen(true)
    }

    const { getRemainingTime, activate } = useIdleTimer({
      onIdle,
      onActive,
      onPrompt,
      timeout: 0.5 * 3_600 * 1_000, // time out in miliseconds --> 0,5 hours
      promptBeforeIdle: 2 * 60 * 1_000, // miliseconds before timeout --> 2 min before idle --> 28min
      throttle: 1_000,
      crossTab: true,
      leaderElection: true,
      syncTimers: 200
    })

    const handleStillHere = (visibility) => {
      setOpen(visibility)
      activate()
    }

    switch (auth.activeNavigator) {
        case "signinSilent":
            return <div>Signing you in ...</div>;
        case "signoutRedirect":
            return <div>Signing you out ...</div>;
    }

    if (auth.isLoading) {
        return <AppLoading />;
    }

    if (auth.error) {
        //console.log("Error message: " + auth.error.message);
        return <LoginPage error={auth.error.message}/>;
    }

    if (auth.isAuthenticated) {
        //console.log("is Authenticated")
        return (
          <div>
            <Authorization />
            <CModal
              backdrop="static"
              visible={open}
              onClose={() => handleStillHere(false)}
              aria-labelledby="StaticBackdropInactivityLabel">
              <CModalHeader>
                <CModalTitle id="StaticBackdropInactivityLabel">Sind Sie noch da?</CModalTitle>
              </CModalHeader>
              <CModalBody>
                <p>
                  Aus Sicherheitsgründen werden Sie ausgeloggt, wenn Sie länger nicht aktiv waren. Bitte bestätigen Sie, dass Sie mit FRAG DEN SCHMIDT weiterhin arbeiten möchten!
                </p>
              </CModalBody>
              <CModalFooter>
                <CButton color="primary" onClick={() => handleStillHere(false)}>
                  Ja, ich möchte eingeloggt bleiben!
                </CButton>
              </CModalFooter>
            </CModal>
          </div>
        );
    }
    else
      return <LoginPage />;
}

export default App
