/* eslint-disable */
import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { CBadge, CSidebar, CSidebarBrand, CSidebarNav, CImage } from '@coreui/react'
import { Link } from 'react-router-dom'
import { AppSidebarNav } from './AppSidebarNav'

import { ReactComponent as CHBLogoNavbarWhite } from 'src/assets/brand/WortbildmarkeCHBECKwhite.svg'
import { ReactComponent as FDGLogoNavbarWhite } from 'src/assets/brand/Wortbildmarke_FdG_neg.svg'
import { ReactComponent as LDALogoNavbarWhite } from 'src/assets/brand/LDA-CMYK-LOGO-WHITE.svg'
import { ReactComponent as FDGWortbildMarkeWhite } from 'src/assets/brand/Wortbildmarke_FdG_neg.svg'

import SimpleBar from 'simplebar-react'
import 'simplebar/dist/simplebar.min.css'
// sidebar nav config
import navigation from '../_nav'

const AppSidebar = () => {
  const dispatch = useDispatch()
  const unfoldable = useSelector((state) => state.sidebarUnfoldable)
  const sidebarShow = useSelector((state) => state.sidebarShow)

  return (
    <CSidebar
      position="fixed"
      unfoldable={unfoldable}
      visible={sidebarShow}
      onVisibleChange={(visible) => {
        dispatch({ type: 'set', sidebarShow: visible })
      }}
    >
      <CSidebarBrand className="d-flex" to="/">
        {false && <CHBLogoNavbarWhite height={25} /> }
        FRAG DEN SCHMIDT
      </CSidebarBrand>
      <CSidebarNav>
        <SimpleBar>
          <AppSidebarNav items={navigation} />
        </SimpleBar>
      </CSidebarNav>
      <CSidebarBrand className="d-flex brandsidebartop">
        <CHBLogoNavbarWhite height={100} />
      </CSidebarBrand>
      <CSidebarBrand className="d-flex ldasidebarlabel">
          Ein Produkt des Verlags C.H.BECK <br></br>
          powered by
      </CSidebarBrand>
      <CSidebarBrand className="d-flex brandsidebarbelow"><br></br>
        <Link to="https://www.legal-data-analytics.com" target="_blank" rel="noopener noreferrer"><LDALogoNavbarWhite height={30} /></Link>
      </CSidebarBrand>
    </CSidebar>
  )
}

export default React.memo(AppSidebar)
